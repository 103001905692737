<template>
  <div
    class="d-flex align-items-center mb-4"
    :class="`justify-content-${justify}`"
  >
    <h2 v-if="size === 'large'" :style="style" class="mr-2 mb-0">
      <slot />
    </h2>

    <h3 v-else-if="size === 'medium'" :style="style" class="mr-2 mb-0">
      <slot />
    </h3>

    <h5 v-else :style="style" class="mr-2 mb-0">
      <slot />
    </h5>

    <b-icon-info-circle
      v-if="info.length !== 0"
      v-b-tooltip.hover.bottom="info"
    />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "small"
    },
    condensed: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    justify: {
      type: String,
      default: "start",
      validator: prop => ["start", "center", "end"].includes(prop)
    },
    info: {
      type: String,
      default: ""
    }
  },

  computed: {
    style() {
      return {
        "font-family": this.fontFamily,
        "font-weight": this.light ? 100 : 700
      };
    },

    fontFamily() {
      if (this.condensed) {
        return "RobotoCondensed !important";
      }
      return "Roboto";
    }
  }
};
</script>

<style scoped></style>
